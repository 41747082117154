import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  return (
    <div className='footer-container'>
            <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>{t('ABOUTUS')}</h2>
            <Link to='/terms'>{t('TERMSOFSERVICE')}</Link>
          
            <Link to='/region'>{t('REGION1')}</Link>
            <Link to='/contact'>{t('CONTACT')}</Link>
          </div>
          <div className='footer-link-items'>
            <h2>{t('TOURIST1')}</h2>
            <a href="https://hebbonair.com/medoc-atlantique-the-return/">{t('NEWSPAPER1')}</a>
            <a href="https://www.medoc-atlantique.com/">Medoc - {t('TOURIST1')}</a>
            <a href="https://www.medoc-atlantique.co.uk/convenient/webcams/soulac-sur-mer-webcam/">Soulac Sur Mer Beachview</a>
            <a href="https://www.mairie-soulac.fr/">Soulac Sur Mer</a>
            
              </div>
        </div>
        
      </div>
      <section className='social-media'>
            <div className='social-media-wrap'>
            <a
        className='social-icon-link instagram'
        href='https://instagram.com/soulac23'
        target='_self'
        aria-label='Instagram'
      >
        <i className='fab fa-instagram' />
      </a>
              <div className='footer-logo'>
                <Link to='/' className='social-logo'>
                Soulac 23
                </Link>
              </div>
              
              <small className='website-rights'>Soulac 23 © 2025</small>
            
            </div>
          </section> 
    </div>
  );
}

export default Footer;