import React from 'react';
import '../App.css';
//import { Button } from './Button';
import './HomeVideo.css';
import imglogo from '../images/23-Logo-rgb-72ppi1.png';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';



function HomeVideoPart() {
  const { t } = useTranslation()
  return (
    <Container className="bgColor">
      <Image className="soulac_logo" src={imglogo} alt="logo" fluid />
      <Row>
        <Col xs={0} md={2}></Col>
        <Col xs={12} md={8}>
          <div className='video-container'>
            <div class="video-wrapper">
              <video playsInline autoPlay loop muted preload="metadata">
                <source src="/videos/Soulac1.mp4" type="video/mp4" />
              </video>
            </div>
            <marquee class="blink" width="80%" direction="left" height="90px">{t('ALERT')}</marquee>
            <h1>  {t('BEDANDBREAKFAST')} <br></br>
              {t('AT')}<br></br>
              Soulac-sur-Mer</h1>
             
            <div className='hero-btns whiteBg'>

              <Link to="/rooms" className='btn-mobile'>
                <Button variant="outline-light">{t('HEROBUTTON1')}</Button>
              </Link>
              <Link to="/booking" className='btn-mobile'>
                <Button variant="light">{t('HEROBUTTON2')}</Button>
              </Link>
            </div>
          </div>
        </Col>
        <Col xs={0} md={2}>
        
        </Col>
        
      </Row>
      
    </Container>
  );
}

export default HomeVideoPart;